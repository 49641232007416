import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-axiom@1.1.1_next@14.2.13_react@18.2.0/node_modules/next-axiom/dist/webVitals/components.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Lato\",\"arguments\":[{\"weight\":[\"400\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"variable\":\"--font-lato\"}],\"variableName\":\"lato\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.13_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/app/src/assets/icons/sprite.svg");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
